<template>
  <div class="feats" v-if="feats.length > 0">
    <div class="h4 title">Особенности</div>
    <div class="list">
      <div v-for="feat in feats" :key="feat" class="item">
        <Icon icon="feats" />
        <span v-html="list ? feat : translate[feat]" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      translate: {
        with_terrace: "Наличие террасы",
        with_large_bathroom: "Квартира с&nbsp;большой ванной комнатой",
        euroformat: "Квартира евроформата",
        master_bedroom: "Мастер-спальня",
        two_bathrooms_and_more: "2&nbsp;ванные комнаты и&nbsp;более",
        view_botanical_garden: "Вид на&nbsp;Ботанический сад",
        view_ostankino_park: "Вид на&nbsp;парк Останкино",
        view_small_botanical_garden: "Вид на&nbsp;Малый Ботанический сад",
        multispace: "Мультипространство (без несущих стен)",
        vestibule_without_transit_ways: "Прихожая без транзитных путей",
        checkroom: "Гардеробная",
        panoramic_windows: "Панорамные окна",
        windows_on_2_sides: "Окна на 2 стороны света",
        view_intersection_park_areas: "Вид на пересечение парковых зон",
        increased_number_windows: "Увеличенное кол-во окон",
      },
    };
  },
  props: {
    list: {
      type: Array,
      default: null
    },
    properties: {
      type: Object,
      required: true,
    },
  },
  computed: {
    feats() {
      if (this.list) {
        return this.list
      }
      const list = Object.keys(this.properties).filter((key) => {
        return (
          this.properties[key] &&
          key !== "with_decoration" &&
          this.translate[key]
        );
      });
      return list;
    },
  },
};
</script>

<style scoped>
.title {
  margin-bottom: 10px;
}

.list {
  font-weight: normal;
  font-size: 18px;
}

.item {
  position: relative;
  padding: 15px 0 15px 25px;
  border-bottom: 1px solid #e5e5e5;
}

.item:last-child {
  border-bottom: 0;
}

.item .icon-feats {
  position: absolute;
  top: 19px;
  left: 0;
}

@media (max-width: 767px) {
  .list {
    font-size: 16px;
  }
  .item {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .item .icon-feats {
    top: 13px;
  }
}
</style>
