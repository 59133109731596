<template>
  <div class="vars">
    <div class="container">
      <div class="h2 title">Варианты отделки</div>
      <swiper
        class="list hasPagination"
        navigation
        :pagination="{ clickable: true }"
        :slides-per-view="'auto'"
        :preload-images="false"
        :space-between="24"
      >
        <swiper-slide v-for="item in vars" :key="item.title">
          <GalleryItem :item="item" />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
SwiperCore.use([Navigation, Pagination]);
import GalleryItem from "@/components/GalleryItem.vue";
export default {
  components: {
    Swiper,
    SwiperSlide,
    GalleryItem,
  },
  props: {
    vars: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.list {
  margin-top: 50px;
}

.list.swiper-container {
  overflow: inherit;
}

.list :deep(.swiper-slide) {
  width: 392px;
}

.list :deep(.item__image) {
  height: 370px;
}
@media (max-width: 991px) {
  .title {
    font-size: 36px;
    letter-spacing: -0.01em;
  }

  .list {
    margin-top: 30px;
  }

  .list :deep(.item__image) {
    height: 350px;
  }

  .list :deep(.item__content) {
    margin-bottom: 15px;
  }

  .list :deep(.swiper-button-prev),
  .list :deep(.swiper-button-next) {
    top: 137px;
    margin-top: 0;
  }
}
@media (max-width: 767px) {
  .list {
    margin-top: 0;
  }

  .list :deep(.swiper-button-prev),
  .list :deep(.swiper-button-next) {
    top: 153px;
  }
}
@media (max-width: 575px) {
  .list :deep(.swiper-slide) {
    width: 300px;
  }
}
@media (max-width: 374px) {
  .list :deep(.swiper-slide) {
    width: 290px;
  }
}
</style>
