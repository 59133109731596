<template>
  <div class="tradein">
    <div class="h4 title">Trade-In</div>
    <div class="text">Выгодно обменяйте Вашу квартиру на&nbsp;новую.</div>
    <div class="btns">
      <a
        class="btn btn-default btn-small"
        data-popup-with-chats
        data-popup-with-chats-title="Trade-in"
      >
        Узнать подробнее
      </a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.tradein {
  padding: 25px;
  background: #f8f8f8;
}

.title {
  margin-bottom: 18px;
}

.btns {
  margin-top: 23px;
}

@media (max-width: 375px) {
  .btns .btn {
    width: 100%;
  }
}
</style>
