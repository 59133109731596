<template>
  <div v-click-outside="vcoConfig" class="share" :class="{ active: isOpen }">
    <button
      class="share-btn btn btn-transparent btn-small"
      @click="isOpen = !isOpen"
    >
      <div class="share-btn__icon">
        <Icon icon="share" />
      </div>
      Поделиться
    </button>
    <div class="share__dropdown">
      <ul>
        <li><div id="yaShare" /></li>
        <li>
          <a href="javascript:void(0)" @click="doCopy">{{ textCopy }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import yaShare from "@/assets/js/yaShare.js";
import useClipboard from "vue-clipboard3";
import { useRoute } from "vue-router";
import { ref, computed } from "vue";
export default {
  props: {
    flatNumber: {
      type: String,
    },
  },
  setup() {
    const isOpen = ref(false);
    const textCopyDefault = "Скопировать ссылку";
    const textCopy = ref(textCopyDefault);

    const route = useRoute();
    const path = computed(() => route.fullPath);

    const { toClipboard } = useClipboard();

    const doCopy = async () => {
      try {
        await toClipboard(window.location.origin + path.value);
        isOpen.value = true;
        textCopy.value = "Скопировано!";
        setTimeout(() => {
          textCopy.value = textCopyDefault;
        }, 3000);
      } catch (e) {
        console.error(e);
      }
    };
    const vcoConfig = function () {
      if (isOpen.value) {
        isOpen.value = false;
      }
    };

    return { isOpen, doCopy, vcoConfig, textCopy };
  },
  mounted() {
    yaShare(
      "yaShare",
      "Квартира №" +
        this.flatNumber +
        " в ЖК VERY Ботанический сад от ГК ОСНОВА",
      window.location.href,
      "https://api.gkosnova.tech/public/storage/media/2022/2/1200/oXzorkK8sRMBrx0yMrKq191wHUQIZLqNza1j99ke.jpg"
    );
  },
};
</script>

<style scoped>
.share {
  position: relative;
  z-index: 1;
}

.share-btn {
  position: relative;
  height: 44px;
  padding-right: 0;
  padding-left: 53px;
  text-align: right;
}

.share-btn__icon {
  position: absolute;
  top: -1px;
  left: -1px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  margin-right: 8px;
  border: 1px solid #e5e5e5;
  border-radius: 50%;
  background: #fff;
  transition: background 0.1s, border-color 0.1s;
}

.share-btn__icon .icon-share {
  margin: 0;
  transition: stroke 0.1s;
}

.share__dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  padding: 10px 15px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 4px 20px rgb(0 0 0 / 0.15);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.1s, visibility 0.1s, transform 0.1s;
}

.share__dropdown ul {
  margin: 0;
  padding: 0;
  list-style: none;
  font-weight: normal;
  font-size: 16px;
  white-space: nowrap;
}

.share__dropdown ul li {
  margin: 0;
  padding: 0;
}

.share__dropdown ul li:last-child {
  margin-bottom: 0;
}

.share__dropdown ul li a {
  display: block;
  padding: 5px 0;
  color: inherit;
  text-decoration: none;
}

body.-notouch .share__dropdown ul li a:hover {
  color: #205640;
}

.share.active .share__dropdown {
  opacity: 1;
  visibility: visible;
  transform: translateY(4px);
}

.share.active .share-btn__icon {
  border-color: #205640;
  background: #205640;
}

.share.active .share-btn__icon .icon-share {
  stroke: #fff;
}

.share-btn {
  position: relative;
  height: 44px;
  padding-right: 0;
  padding-left: 53px;
  text-align: right;
}

.share-btn__icon {
  position: absolute;
  top: -1px;
  left: -1px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  margin-right: 8px;
  border: 1px solid #e5e5e5;
  border-radius: 50%;
  background: #fff;
  transition: background 0.1s, border-color 0.1s;
}

.share-btn__icon .icon-share {
  margin: 0;
  transition: stroke 0.1s;
}

.share__dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  padding: 10px 15px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 4px 20px rgb(0 0 0 / 0.15);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.1s, visibility 0.1s, transform 0.1s;
}

.share__dropdown ul {
  margin: 0;
  padding: 0;
  list-style: none;
  font-weight: normal;
  font-size: 16px;
  white-space: nowrap;
}

.share__dropdown ul li {
  margin: 0;
  padding: 0;
}

.share__dropdown ul li:last-child {
  margin-bottom: 0;
}

.share__dropdown ul li a {
  display: block;
  padding: 5px 0;
  color: inherit;
  text-decoration: none;
}

.share.active .share__dropdown {
  opacity: 1;
  visibility: visible;
  transform: translateY(4px);
}

.share.active .share-btn__icon {
  border-color: #205640;
  background: #205640;
}

.share.active .share-btn__icon .icon-share {
  stroke: #fff;
}

body.-notouch .share-btn:hover .share-btn__icon {
  border-color: #a6bbb3;
}

body.-notouch .share-btn:active .share-btn__icon {
  border-color: #205640;
}

body.-notouch .share.active .share-btn:hover .share-btn__icon {
  border-color: #366753;
  background: #366753;
}

body.-notouch .share.active .share-btn:active .share-btn__icon {
  border-color: #1d4d3a;
  background: #1d4d3a;
}

.ya-share2 {
  padding: 5px 0px;
}
</style>
