<template>
  <div class="flat-view-trigger">
    <img src="@/assets/i/flatViewTrigger.jpg">
    <div class="flat-view-trigger__text">
      <span>Посмотреть вид из окна</span>
    </div>
  </div>
</template>

<style>
.flat-view-trigger {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #205640;
}
.flat-view-trigger img {
  display: block;
  border-radius: 4px;
}
.flat-view-trigger__text {
  text-align: center;
}
.flat-view-trigger__text span {
  border-bottom: 1px dashed currentColor;
}
</style>
