<template>
  <div v-if="mortgage" class="mortgage">
    <div class="h4 title">
      Ипотека от&nbsp;{{ mortgage.min_mortgage_percent }}%
    </div>
    <div class="list">
      <div
        v-for="item in filteredList"
        :key="item.bank_name"
        class="item d-flex align-items-center"
      >
        <div class="item__logo">
          <img
            v-if="item.bank_image_url"
            :src="item.bank_image_url"
            loading="lazy"
            width="108"
            height="36"
            :alt="item.bank_name"
          />
        </div>
        <div class="item__rules d-flex">
          <div class="item__rule">
            от&nbsp;{{ item.bank_bet }}%
            <span>ставка</span>
          </div>
          <div class="item__rule">
            от&nbsp;{{ item.bank_min_pay }}%
            <span>мин. взнос</span>
          </div>
          <div class="item__rule">
            до&nbsp;{{ item.bank_max_time }}&nbsp;лет
            <span>срок</span>
          </div>
        </div>
      </div>
    </div>
    <div class="mortgage-list__btn">
      <button
        class="btn btn-default btn-small"
        data-popup-with-chats
        data-popup-with-chats-title="Получить предложение"
      >
        Получить предложение
      </button>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["mainStore"],
  computed: {
    mortgage() {
      return this.mainStore.state.data?.mortgage;
    },
    filteredList() {
      let list = this.mortgage.project_banks;
      list = list.slice(0, 4);
      return list;
    },
  },
};
</script>

<style scoped>
.title {
  margin-bottom: 10px;
}

.list {
  margin-bottom: 10px;
}

.list:last-child {
  margin-bottom: 0;
}

.item {
  padding: 14px 0 16px;
  border-bottom: 1px solid #e5e5e5;
}

.item:last-child {
  border-bottom: 0;
}

.item__logo {
  flex: 0 0 108px;
  margin-right: 10px;
  text-align: center;
}

.item__logo img {
  width: auto;
  height: auto;
  filter: grayscale(1);
}

.item__rules {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.item__rule {
  width: 100%;
  font-weight: normal;
  font-size: 14px;
}

.item__rule:nth-child(1) {
  width: 34%;
}

.item__rule:nth-child(2) {
  width: 34%;
}

.item__rule:nth-child(3) {
  width: 32%;
}

.item__rule span {
  display: block;
  color: #898c8a;
  font-weight: 300;
  font-size: 12px;
}
@media (max-width: 375px) {
  .mortgage-list__btn .btn {
    width: 100%;
  }
}
@media (max-width: 374px) {
  .item__logo {
    flex: 0 0 84px;
  }
}
</style>
